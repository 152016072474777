@font-face {
  font-family: 'Publico Banner';
  src: 
      url('PublicoBanner-LightItalic-Web.woff2') format('woff2'),
      url('PublicoBanner-LightItalic-Web.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Publico Banner';
  src:
      url('PublicoBanner-Light-Web.woff2') format('woff2'),
      url('PublicoBanner-Light-Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


