// Button
.mat-button,
.mat-stroked-button,
.mat-flat-button {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  letter-spacing: .018em !important;
  padding: 0 24px !important;
}

// Dialog
.mat-dialog-container {
  border-radius: 6px !important;
  padding: 32px 48px !important;
}

.mat-dialog-title,
.mat-typography .mat-dialog-title {
  color: var(--color-cape-cod);
  font-size: 2rem;
  font-weight: 400;
  line-height: 3.6rem;
}

.mat-dialog-content,
.mat-typography .mat-dialog-content {
  color: var(--color-shuttle-gray);
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: .018em;
  line-height: 2.4rem;
  overflow: auto;
}

.mat-dialog-actions {
  margin-bottom: 0 !important;
  padding: 32px 0 0 !important;
}

// Tooltip
.mat-tooltip {
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: .02em;
  line-height: 2.4rem;
  padding: 0 8px;
}

// Menu
.mat-menu-panel {
  border-radius: 8px !important;
  max-width: 300px !important;
  min-height: 48px !important; // (line-height + padding-top + padding-bottom) from '.mat-menu-item'
  a {
    height: auto;
    overflow: visible;
    white-space: normal;
    word-break: break-word;
  }
}

.mat-menu-content {
  padding: 0 !important;
}

.mat-menu-item {
  color: var(--color-cape-cod) !important;
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  letter-spacing: .018em !important;
  line-height: 2rem !important;
  min-width: 256px !important;
  padding: 14px 24px !important;

  &:hover {
    background: var(--color-iron) !important;
  }

  &[disabled] {
    opacity: .5;
  }

  &[active='true'] {
    background: var(--color-denim) !important;
    color: var(--color-white) !important;
  }
}

.mat-menu-item-submenu-trigger {
  &[active='true'] {
    &::after {
      border-color: transparent transparent transparent var(--color-white);
    }
  }
}

// Tabs
.mat-tab-group {
  height: 100%;

  .mat-tab-body-wrapper {
    height: 100%;
  }

  .mat-tab-header {
    border-bottom: 1px solid var(--color-gray-chateau);
  }

  .mat-tab-label {
    color: var(--color-cape-cod);
    font-size: 1.4rem;
    font-weight: 500;
    height: auto;
    letter-spacing: .018em;
    line-height: 2rem;
    margin-right: 16px;
    min-width: unset;
    opacity: 1;
    padding: 24px 28px;
  }

  .mat-tab-label-active {
    color: var(--color-denim);
  }

  .mat-ink-bar {
    height: 4px;
  }
}

.mat-expansion-panel-header {
  font-family: var(--font-untitled-sans);
}
