/* stylelint-disable */
@use '~@angular/material' as mat;
@import '~@angular/material/theming';
/* stylelint-enable */

@include mat.core();

$color-denim: #1967d2;
$color-white: #fff;
/* stylelint-disable number-leading-zero */
$theme-primary-color: (
  50: lighten($color-denim, 50%),
  100: lighten($color-denim, 40%),
  200: lighten($color-denim, 30%),
  300: lighten($color-denim, 20%),
  400: lighten($color-denim, 10%),
  500: $color-denim,
  600: darken($color-denim, 10%),
  700: darken($color-denim, 20%),
  800: darken($color-denim, 30%),
  900: darken($color-denim, 40%),
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    a100: rgba(black, 0.87),
    a200: white,
    a400: white,
    a700: white,
  ),
);

$background-color: $color-white;
$theme-primary: mat.define-palette($theme-primary-color);
$theme-accent: mat.define-palette(mat.$blue-palette);
$theme-warn: mat.define-palette(mat.$deep-orange-palette, a200);

$theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);

$background: map-get($theme, background);

$background: map_merge(
  $background,
  (
    background: $background-color,
  )
);

$theme: map_merge(
  $theme,
  (
    background: $background,
  )
);

@include mat.all-component-themes($theme);

// override so that untitled sans is the main font
// except headers which are publico
$my-custom-typography: mat.define-typography-config(
  $font-family: var(--font-untitled-sans),
  $headline:
    mat.define-typography-level(
      $font-family: var(--font-publico-banner),
      $font-size: 24px,
    ),
  $title:
    mat.define-typography-level(
      $font-family: var(--font-publico-banner),
      $font-size: 20px,
    ),
  $subheading-1:
    mat.define-typography-level(
      $font-family: var(--font-publico-banner),
      $font-size: 16px,
    ),
);

@include mat.core($my-custom-typography);
