:root {
  --breakpoint-mobile: 375px;
  --breakpoint-tablet: 768px;
  --breakpoint-desktop: 1024px;
  --breakpoint-large-desktop: 1280px;
  --breakpoint-extra-large-desktop: 1440px;
}

$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-large-desktop: 1280px;
$breakpoint-extra-large-desktop: 1440px;

@mixin breakpoint-tablet {
  @media (min-width: $breakpoint-tablet) {
    @content;
  }
}
@mixin breakpoint-desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}
@mixin breakpoint-large-desktop {
  @media (min-width: $breakpoint-large-desktop) {
    @content;
  }
}
@mixin breakpoint-extra-large-desktop {
  @media (min-width: $breakpoint-extra-large-desktop) {
    @content;
  }
}
