.risk-button {
  border-radius: 4px;
  color: white;
  cursor: default;
  display: inline-block;
  flex-shrink: 0;
  font-size: 1rem;
  font-weight: 500;
  height: 28px;
  letter-spacing: .073em;
  line-height: 1.6rem;
  padding: 5px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: top;
  white-space: nowrap;
  width: 7.5rem;

  &.VeryHigh {
    background-color: var(--color-punch);
  }

  &.High {
    background: var(--color-gold-drop);
  }

  &.Medium {
    background-color: var(--color-sunglow);
    color: var(--color-cape-cod);
  }

  &.Low {
    background: var(--color-forest-green);
  }

  &.NA {
    background: var(--color-aluminium);
  }

  &.InsufficientData {
    background: var(--color-oslo-gray);
    font-size: .8rem;
    line-height: 1.2;
    white-space: break-spaces;
  }
}
