/* naming convention http://chir.ag/projects/name-that-color */
:root {
  --color-black: #000;
  --color-white: #fff;
  --color-off-white: #f6f5ef;
  --color-malibu: #8ab4f8;
  --color-cape-cod: #3c4043;
  --color-shark: #202124;
  --color-oslo-gray: #80868b;
  --color-shuttle-gray: #5f6368;
  --color-gray-chateau: #9aa0a6;
  --color-aluminium: #acb2b9;
  --color-iron: #dadce0;
  --color-porcelain: #f1f3f4;
  --color-athens-gray: #f9fafb;
  --color-athens-gray-dark: #e8eaed;
  --color-solitude: #e8f0fe;
  --color-fun-blue: #174ea6;
  --color-denim: #1967d2;
  --color-fern: #5bb974;
  --color-hawkes-blue: #d2e3fc;
  --color-tabasco: #b31412;
  --color-cinnabar: #ea4335;
  --color-azalea: #fad2cf;
  --color-taupe: #aeaa9b;

  // Risk
  --color-forest-green: #1e8e3e;
  --color-sunglow: #fcc934;
  --color-gold-drop: #ea8600;
  --color-punch: #d93025;
}
