@font-face {
  font-display: swap;
  font-family: 'Publico Banner';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/publicoBanner/PublicoBanner-Light-Web.woff2')
      format('woff2'),
    url('../assets/fonts/publicoBanner/PublicoBanner-Light-Web.woff')
      format('woff');
}

:root {
  --font-google-sans: 'Untitled Sans', sans-serif;
  --font-untitled-sans: 'Untitled Sans', sans-serif;
  --font-roboto: 'Roboto', 'Helvetica Neue', sans-serif;
  --font-publico-banner: 'Publico Banner', sans-serif;
}
