@import '~firebaseui/dist/firebaseui.css';
@import './styles/index.scss';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  font-size: 62.5%;
  line-height: 1.3;
}

html {
  background-color: transparent;
  font-size: 62.5%;
  height: 100%;
}

body {
  background-color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto;
  height: 100%;
  margin: 0;
  min-height: 100%;
  padding: 0;

  &.no-scroll {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
span,
input,
label,
textarea,
time,
button,
address {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--color-denim);
}

blockquote,
dd,
div,
dl,
dt,
form,
h1,
h2,
h3,
h4,
h5,
h6,
li,
ol,
p,
pre,
td,
th,
ul {
  margin: 0;
  padding: 0;
}
